<template>
    <div>
        <h2>多人音视频通话</h2>
        <el-rate
        v-model="value"
        show-text>
        </el-rate>
    </div>
</template>

<script>
export default {
data(){
    return{
        value: null,
    }
},
mounted(){

},
methods:{

}
}
</script>
<style>

</style>
