<template>
  <div>
    <el-container>
      <el-header>
        <HeaghtListVue/>
      </el-header>
      <el-main>
        <webrtcdemo/>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import webrtcdemo from './WebRtcdemo.vue'
import HeaghtListVue from './HeaghtList.vue'
export default {
  data(){
    return{
      
    }
  },
  components:{
    webrtcdemo,
    HeaghtListVue
  },
  mounted(){
    this.webrtc()
  },
  methods:{
  }
}
</script>
<style>
  .el-header {
    background: linear-gradient(to right, rgb(124, 228, 247),  rgb(216, 255, 153));
    color: #333;
    text-align: center;
    height: 15vh !important;
  }
  .el-main {
    background: linear-gradient(to right, rgb(112, 225, 245),  rgb(255, 209, 148));
    color: #333;
    height: 85vh;
  }
</style>
